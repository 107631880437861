export const UPDATE_FIELD_AUTH = 'UPDATE_FIELD_AUTH'
export const RESET_FIELD_AUTH = 'RESET_FIELD_AUTH'
export const ERROR_FIELD_AUTH = 'ERROR_FIELD_AUTH'
export const UPDATE_FIELD_TEXT = 'UPDATE_FIELD_TEXT'
export const RESET_FORM = 'RESET_FORM'
export const REGISTER_ACCOUNT_REQUEST = 'REGISTER_ACCOUNT_REQUEST'
export const REGISTER_ACCOUNT_SUCCESS = 'REGISTER_ACCOUNT_SUCCESS'
export const REGISTER_ACCOUNT_FAILURE = 'REGISTER_ACCOUNT_FAILURE'
export const CLEAR_SIGNUP_ERRORS = 'CLEAR_SIGNUP_ERRORS'
export const PUSH_NOTIFICATION = 'PUSH_NOTIFICATION'
export const DISMISS_NOTIFICATION_BY_MESSAGE =
  'DISMISS_NOTIFICATION_BY_MESSAGE'

export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'
export const CLEAR_CURRENT_USER = 'CLEAR_CURRENT_USER'
export const CLEAR_LOGIN_ERRORS = 'CLEAR_LOGIN_ERRORS'
export const CONNECT_ACCOUNT = 'CONNECT_ACCOUNT'
export const DISCONNECT_ACCOUNT = 'DISCONNECT_ACCOUNT'
export const GET_USER_LIST_SUCCESS = 'GET_USER_LIST_SUCCESS'
export const GET_USER_LIST_FAILURE = 'GET_USER_LIST_FAILURE'
export const SHELF_REFRESH_SUCCESS = 'SHELF_REFRESH_SUCCESS'

export const GET_BLOG_POST_REQUEST = 'GET_BLOG_POST_REQUEST'
export const GET_BLOG_POST_SUCCESS = 'GET_BLOG_POST_SUCCESS'
export const GET_BLOG_POST_FAILURE = 'GET_BLOG_POST_FAILURE'
export const GET_ALL_BLOG_POSTS_REQUEST = 'GET_ALL_BLOG_POSTS_REQUEST'
export const GET_ALL_BLOG_POSTS_SUCCESS = 'GET_ALL_BLOG_POSTS_SUCCESS'
export const GET_ALL_BLOG_POSTS_FAILURE = 'GET_ALL_BLOG_POSTS_FAILURE'

export const SET_MAGIC_LENGTH = 'SET_MAGIC_LENGTH'
export const SET_MAGIC_VALUES = 'SET_MAGIC_VALUES'
export const CLEAR_MAGIC_VALUES = 'CLEAR_MAGIC_VALUES'

export const SUPER_LOGIN_REQUEST = 'SUPER_LOGIN_REQUEST'
export const SUPER_LOGIN_SUCCESS = 'SUPER_LOGIN_SUCCESS'
export const SUPER_LOGIN_FAILURE = 'SUPER_LOGIN_FAILURE'
export const CLEAR_CURRENT_SUPER_INFO = 'CLEAR_CURRENT_SUPER_INFO'

export const STRIPE_PUBLISHABLE_TEST_KEY = 'pk_live_' 
  + '51N3WiQFYHcKM5vsb4WirmcQQvWZQ52JnLRQgiEmyCH9qy05a2Ju3AU8UfApZFz4CjnF8XVPZA8vCtft41D2VNiu000JBve8IAg'