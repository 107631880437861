export const bucketPrefix =
  "https://storage.googleapis.com/dalmadaniela.com/assets";

export const i = {
  dalma: {
    collage: `${bucketPrefix}/dalma/collage.png`,
    instagramPhone: `${bucketPrefix}/dalma/instagramPhone.png`,
    landingPhoto: `${bucketPrefix}/dalma/landingPhoto.png`,
    linkedInLaptop: `${bucketPrefix}/dalma/linkedInLaptop.png`,
    onPhone: `${bucketPrefix}/dalma/onPhone.png`,
    pointingUp: `${bucketPrefix}/dalma/pointingUp.png`,
    iVisaPhoto: `${bucketPrefix}/dalma/iVisaPhoto.JPG`,
    brownBackground: `${bucketPrefix}/dalma/brownBackground.jpeg`,
    brownBackground2: `${bucketPrefix}/dalma/brownBackground2.jpeg`,
    redSweater1: `${bucketPrefix}/dalma/redSweater1.webp`,
    redSweater2: `${bucketPrefix}/dalma/redSweater2.webp`,
    redSweater3: `${bucketPrefix}/dalma/redSweater3.webp`,
    toga: `${bucketPrefix}/dalma/toga.JPG`,
  },
  films: {
    limeAndVinegar: `${bucketPrefix}/films/limeAndVinegar.png`,
    theSovereign: `${bucketPrefix}/films/theSovereign.png`,
    deadPower: `${bucketPrefix}/films/deadPower.webp`,
  },
  acting: {
    celebMixCover: `${bucketPrefix}/acting/celebMixCover.webp`,
    evan: `${bucketPrefix}/acting/evan.webp`,
    farah: `${bucketPrefix}/acting/farah.jpg`,
    flanelleCover: `${bucketPrefix}/acting/flanelleCover.webp`,
    headshot1: `${bucketPrefix}/acting/headshot1.webp`,
    headshot2: `${bucketPrefix}/acting/headshot2.jpeg`,
    headshot3: `${bucketPrefix}/acting/headshot3.jpeg`,
    headshot4: `${bucketPrefix}/acting/headshot4.webp`,
    newYorkWeeklyCover: `${bucketPrefix}/acting/newYorkWeeklyCover.webp`,
    resume: `${bucketPrefix}/acting/resume.jpeg`,
  },
  icons: {
    add: `${bucketPrefix}/icons/add.png`,
    arrow: `${bucketPrefix}/icons/arrow.webp`,
    backBlack: `${bucketPrefix}/icons/backBlack.png`,
    backPink: `${bucketPrefix}/icons/backPink.png`,
    backYellow: `${bucketPrefix}/icons/backYellow.png`,
    burger: `${bucketPrefix}/icons/burger.png`,
    check: `${bucketPrefix}/icons/check.svg`,
    checkMark: `${bucketPrefix}/icons/checkMark.png`,
    copy: `${bucketPrefix}/icons/copy.png`,
    close: `${bucketPrefix}/icons/close.png`,
    document: `${bucketPrefix}/icons/document.svg`,
    documents: `${bucketPrefix}/icons/documents.png`,
    email: `${bucketPrefix}/icons/email.svg`,
    face: `${bucketPrefix}/icons/face.svg`,
    facebook: `${bucketPrefix}/icons/facebook.svg`,
    facebookRealIcon: `${bucketPrefix}/icons/facebookRealIcon.png`,
    home: `${bucketPrefix}/icons/home.svg`,
    instagram: `${bucketPrefix}/icons/instagram.svg`,
    instagramRealIcon: `${bucketPrefix}/icons/instagramRealIcon.png`,
    linkedIn: `${bucketPrefix}/icons/linkedIn.svg`,
    satisfactionGuarentee: `${bucketPrefix}/icons/satisfactionGuarentee.png`,
    shareNeon: `${bucketPrefix}/icons/shareNeon.webp`,
    syringe: `${bucketPrefix}/icons/syringe.png`,
    threads: `${bucketPrefix}/icons/threads.svg`,
    threadsRealIcon: `${bucketPrefix}/icons/threadsRealIcon.png`,
    tikTok: `${bucketPrefix}/icons/tikTok.png`,
    user: `${bucketPrefix}/icons/user.png`,
    youtube: `${bucketPrefix}/icons/youtube.svg`,
  },
  logos: {
    setCollaborated: {
      avenida: `${bucketPrefix}/logos/avenida.jpeg`,
      forty4Blue: `${bucketPrefix}/logos/44Blue.jpeg`,
      cambur: `${bucketPrefix}/logos/cambur.jpeg`,
      skinflint: `${bucketPrefix}/logos/skinflint.jpeg`,
      speak: `${bucketPrefix}/logos/speak.jpeg`,
      everpost: `${bucketPrefix}/logos/everpost.jpeg`,
      abogados: `${bucketPrefix}/logos/abogados.jpeg`,
    },
    brandsHelped: {
      iVisa: `${bucketPrefix}/logos/iVisa.jpeg`,
      fridays: `${bucketPrefix}/logos/fridays.png`,
      mamb: `${bucketPrefix}/logos/mamb.png`,
      it49: `${bucketPrefix}/logos/it49.png`,
      errante: `${bucketPrefix}/logos/errante.png`,
      civitasNetworks: `${bucketPrefix}/logos/civitasNetworks.webp`,
      artivistsUnited: `${bucketPrefix}/logos/artivistsUnited.webp`,
    },
    brandsCollaborated: {
      hardRockCafe: `${bucketPrefix}/logos/hardRockCafe.png`,
      uptime: `${bucketPrefix}/logos/uptime.png`,
      versed: `${bucketPrefix}/logos/versed.png`,
      eoApp: `${bucketPrefix}/logos/eoApp.png`,
      lilyJade: `${bucketPrefix}/logos/lilyJade.png`,
      sunMed: `${bucketPrefix}/logos/sunMed.png`,
      bioOptimizers: `${bucketPrefix}/logos/bioOptimizers.png`,
      sunWink: `${bucketPrefix}/logos/sunWink.png`,
      dominant: `${bucketPrefix}/logos/dominant.png`,
      vireo: `${bucketPrefix}/logos/vireo.png`,
      madresBrunch: `${bucketPrefix}/logos/madresBrunch.webp`,
      nelsonJ: `${bucketPrefix}/logos/nelsonJ.webp`,
    },
    brandsFeatured: {
      shoutoutLA: {
        imgUrl: `${bucketPrefix}/logos/shoutoutLA.jpeg`,
        linkUrl:
          "https://shoutoutla.com/meet-dalma-llinas-actress-singer-digital-creator/",
      },
      voyageLA: {
        imgUrl: `${bucketPrefix}/logos/voyageLA.jpeg`,
        linkUrl:
          "https://voyagela.com/interview/check-out-dalma-llinass-story/",
      },
      canvasRebel: {
        imgUrl: `${bucketPrefix}/logos/canvasRebel.jpeg`,
        linkUrl: "https://canvasrebel.com/meet-dalma-daniela/",
      },
      laWeekly: {
        imgUrl: `${bucketPrefix}/logos/laWeekly.png`,
        linkUrl:
          "https://www.laweekly.com/top-female-entrepreneurs-to-follow-as-the-year-unfolds/",
      },
      nyWeekly: {
        imgUrl: `${bucketPrefix}/logos/nyWeekly.png`,
        linkUrl:
          "https://nyweekly.com/entertainment/unveiling-the-multi-talented-dalma-daniela-a-fusion-of-artistry-entrepreneurship-and-advocacy/",
      },
      femaleFirst: {
        imgUrl: `${bucketPrefix}/logos/femaleFirst.png`,
        linkUrl:
          "https://www.femalefirst.co.uk/features/dalma-daniela-named-top-female-entrepreneur-track-1376414.html",
      },
      celebMix: {
        imgUrl: `${bucketPrefix}/logos/celebMix.png`,
        linkUrl:
          "https://celebmix.com/innovative-approaches-to-bilingualism-how-dalma-danielas-cultural-programs-made-a-difference/",
      },
      flanelle: {
        imgUrl: `${bucketPrefix}/logos/flanelle.png`,
        linkUrl:
          "https://flanellemag.com/from-acting-to-artistry-the-evolving-career-of-colombian-icon-dalma-daniela/",
      },
    },
  },
  magic: {
    magic1: `${bucketPrefix}/magic/magic1.webp`,
    magic2: `${bucketPrefix}/magic/magic2.webp`,
    magic3: `${bucketPrefix}/magic/magic3.webp`,
    magic4: `${bucketPrefix}/magic/magic4.webp`,
    magic5: `${bucketPrefix}/magic/magic5.webp`,
    magic6: `${bucketPrefix}/magic/magic6.webp`,
    magic7: `${bucketPrefix}/magic/magic7.webp`,
    magic8: `${bucketPrefix}/magic/magic8.webp`,
    magic9: `${bucketPrefix}/magic/magic9.webp`,
    magic10: `${bucketPrefix}/magic/magic10.webp`,
    magicCloud: `${bucketPrefix}/magic/magicCloud.png`,
  },
  magicCalendars: {
    inimtableGrid: `${bucketPrefix}/magicCalendars/inimtableGrid.png`,
    landingPhoto: `${bucketPrefix}/magicCalendars/landingPhoto.png`,
    lightbulbConstruction: `${bucketPrefix}/magicCalendars/lightbulbConstruction.gif`,
    smallBusinessGuy: `${bucketPrefix}/magicCalendars/smallBusinessGuy.png`,
    subtitle: `${bucketPrefix}/magicCalendars/subtitle.png`,
    title: `${bucketPrefix}/magicCalendars/title.png`,
    questions: {
      question1: `${bucketPrefix}/magicCalendars/questions/question1.png`,
      question2: `${bucketPrefix}/magicCalendars/questions/question2.png`,
      question3: `${bucketPrefix}/magicCalendars/questions/question3.png`,
      question4: `${bucketPrefix}/magicCalendars/questions/question4.png`,
      question5: `${bucketPrefix}/magicCalendars/questions/question5.png`,
      question8: `${bucketPrefix}/magicCalendars/questions/question8.png`,
      question9: `${bucketPrefix}/magicCalendars/questions/question9.png`,
      question10: `${bucketPrefix}/magicCalendars/questions/question10.png`,
    },
  },
  stars: {
    starDownwardSpiral: `${bucketPrefix}/stars/starDownwardSpiral.webp`,
    starSwirl: `${bucketPrefix}/stars/starSwirl.webp`,
    starTwinkleSmall: `${bucketPrefix}/stars/starTwinkleSmall.webp`,
    starTwinklesLarge: `${bucketPrefix}/stars/starTwinklesLarge.webp`,
  },
  stock: {
    artsyGirl: `${bucketPrefix}/stock/artsyGirl.webp`,
    bigBenContentCalendar: `${bucketPrefix}/stock/bigBenContentCalendar.png`,
    brand: `${bucketPrefix}/stock/brand.png`,
    bullseye: `${bucketPrefix}/stock/bullseye.webp`,
    computerHands: `${bucketPrefix}/stock/computerHands.png`,
    consistency: `${bucketPrefix}/stock/consistency.jpeg`,
    conactUs: `${bucketPrefix}/stock/contactUs.jpeg`,
    contentCalendarAssets: `${bucketPrefix}/stock/contentCalendarAssets.png`,
    girlOnPhoneOverYellow: `${bucketPrefix}/stock/girlOnPhoneOverYellow.png`,
    groupOfPeopleInCircle: `${bucketPrefix}/stock/groupOfPeopleInCircle.png`,
    instagramFeed: `${bucketPrefix}/stock/instagramFeed.png`,
    jayShettyVideo: `${bucketPrefix}/stock/jayShettyVideo.png`,
    lightbulbConstruction: `${bucketPrefix}/stock/lightbulbConstruction.svg`,
    phoneAtCoffeeShop: `${bucketPrefix}/stock/phoneAtCoffeeShop.png`,
    purpose: `${bucketPrefix}/stock/purpose.webp`,
    smallBusinessOwner: `${bucketPrefix}/stock/smallBusinessOwner.png`,
    survey: `${bucketPrefix}/stock/survey.png`,
    wand: `${bucketPrefix}/stock/wand.png`,
    whiteChick: `${bucketPrefix}/stock/whiteChick.png`,
  },
  services: {
    arianaPodcast: `${bucketPrefix}/services/arianaPodcast.webp`,
    businessCards: `${bucketPrefix}/services/businessCards.png`,
    colorPallette: `${bucketPrefix}/services/colorPallette.png`,
    podcastGirl: `${bucketPrefix}/services/podcastGirl.webp`,
    socialMediaGrid: `${bucketPrefix}/services/socialMediaGrid.webp`,
    laptop: `${bucketPrefix}/services/laptop.webp`,
  },
  superClass: {
    adTrailerStill: `${bucketPrefix}/superclass/superClassAdTrailerStill.png`,
  },
  team: {
    cami: `${bucketPrefix}/team/cami.jpeg`,
    corey: `${bucketPrefix}/team/corey.jpeg`,
    dalma: `${bucketPrefix}/team/dalma.jpeg`,
    juancho: `${bucketPrefix}/team/juancho.jpeg`,
  },
  testimonials: {
    alexPhone: `${bucketPrefix}/testimonials/alexPhone.png`,
    alexHeadshot: `${bucketPrefix}/testimonials/alexHeadshot.jpg`,
    camiPhone: `${bucketPrefix}/testimonials/camiPhone.png`,
    camiHeadshot: `${bucketPrefix}/testimonials/camiHeadshot.jpg`,
    evanPhone: `${bucketPrefix}/testimonials/evanPhone.png`,
    evanHeadshot: `${bucketPrefix}/testimonials/evanHeadshot.jpg`,
    garettPhone: `${bucketPrefix}/testimonials/garettPhone.png`,
    garettHeadshot: `${bucketPrefix}/testimonials/garettHeadshot.jpg`,
  },
  videos: {
    emojiBlast: `${bucketPrefix}/videos/emojiBlast.mp4`,
    lightbulbConstruction: `${bucketPrefix}/videos/lightbulbConstruction.gif`,
    magicCalendarsAd: `${bucketPrefix}/videos/magicCalendarsAd.mp4`,
    reelArcShoot: `${bucketPrefix}/videos/1.%20DALMA%20DANIELA%20-%20DRAMATIC%20REEL.mov`,
    ugcVideo: `${bucketPrefix}/videos/ugcVideo.mp4`,
  },
};
