import shuffle from 'lodash-es/shuffle.js'

const MENTION_SUGGESTIONS = shuffle([
    { id: 8218877932, name: 'Adrien Brody' },
    { id: 2376248434, name: 'Al Pacino' },
    { id: 1536792675, name: 'Alain Delon' },
    { id: 7582311426, name: 'Alec Baldwin' },
    { id: 5441252154, name: 'Andie MacDowell' },
    { id: 8140398438, name: 'Angelina Jolie' },
    { id: 3777716804, name: 'Annette Bening' },
    { id: 4478885052, name: 'Anthony Hopkins' },
    { id: 3100863011, name: 'Antonio Banderas' },
    { id: 8874179979, name: 'Arnold Schwarzenegger' },
    { id: 3432983875, name: 'Audrey Tautou' },
    { id: 9000940982, name: 'Barbra Streisand' },
    { id: 4759170383, name: 'Ben Kingsley' },
    { id: 5956789123, name: 'Bette Midler' },
    { id: 8470497758, name: 'Billy Bob Thornton' },
    { id: 2860927956, name: 'Brad Pitt' },
    { id: 4457661309, name: 'Bridget Fonda' },
    { id: 7343443712, name: 'Bruce Willis' },
    { id: 2465030183, name: 'Cameron Diaz' },
    { id: 5614659455, name: 'Carrie-Anne Moss' },
    { id: 7659120581, name: 'Cate Blanchett' },
    { id: 7075961888, name: 'Catherine Deneuve' },
    { id: 5978067317, name: 'Catherine Keener' },
    { id: 4935868783, name: 'Charlotte Rampling' },
    { id: 8906544316, name: 'Charlton Heston' },
    { id: 1959101013, name: 'Cher' },
    { id: 3841920438, name: 'Chris Cooper' },
    { id: 6222459463, name: 'Christian Bale' },
    { id: 6730011157, name: 'Christopher Walken' },
    { id: 9753937741, name: 'Claudia Cardinale' },
    { id: 2019696630, name: 'Clint Eastwood' },
    { id: 6191245793, name: 'Colin Firth' },
    { id: 6865092325, name: 'Courteney Cox' },
    { id: 5064838948, name: 'Daniel Day-Lewis' },
    { id: 6192534926, name: 'Daryl Hannah' },
    { id: 3170010085, name: 'Debra Winger' },
    { id: 4473889068, name: 'Demi Moore' },
    { id: 1225854407, name: 'Denzel Washington' },
    { id: 4595551363, name: 'Diane Keaton' },
    { id: 9442532677, name: 'Diane Kruger' },
    { id: 2408231736, name: 'Dustin Hoffman' },
    { id: 8304547201, name: 'Ed Harris' },
    { id: 1244620246, name: 'Edward Norton' },
    { id: 4116412937, name: 'Elisabeth Shue' },
    { id: 5661480326, name: 'Elizabeth Taylor' },
    { id: 6975081913, name: 'Elliot Page' },
    { id: 2834440972, name: 'Emily Mortimer' },
    { id: 7569742737, name: 'Emma Thompson' },
    { id: 7011431551, name: 'Emmanuelle Seigner' },
    { id: 5541332877, name: 'F. Murray Abraham' },
    { id: 8006942005, name: 'Fairuza Balk' },
    { id: 2729685111, name: 'Faye Dunaway' },
    { id: 3421423628, name: 'Forest Whitaker' },
    { id: 2758495686, name: 'Frances McDormand' },
    { id: 7492110081, name: 'Gary Oldman' },
    { id: 1838559507, name: 'Geena Davis' },
    { id: 2418976696, name: 'Gene Hackman' },
    { id: 4637367751, name: 'Geoffrey Rush' },
    { id: 8122384676, name: 'George Clooney' },
    { id: 1582145121, name: 'Gillian Anderson' },
    { id: 3826097343, name: 'Gina Gershon' },
    { id: 1899996303, name: 'Giulietta Masina' },
    { id: 8213809556, name: 'Glenn Close' },
    { id: 2769654314, name: 'Goldie Hawn' },
    { id: 3973945098, name: 'Gwyneth Paltrow' },
    { id: 6486781572, name: 'Gérard Depardieu' },
    { id: 5190636267, name: 'Halle Berry' },
    { id: 7615641462, name: 'Harrison Ford' },
    { id: 8247258609, name: 'Harvey Keitel' },
    { id: 5312890529, name: 'Heath Ledger' },
    { id: 6912657435, name: 'Helen Hunt' },
    { id: 3010776063, name: 'Helen Mirren' },
    { id: 3207878474, name: 'Helena Bonham Carter' },
    { id: 9871381263, name: 'Henry Fonda' },
    { id: 6386622812, name: 'Hilary Swank' },
    { id: 3421420327, name: 'Hugo Weaving' },
    { id: 2300368234, name: 'Ingrid Bergman' },
    { id: 9618430022, name: 'Jack Nicholson' },
    { id: 1178907031, name: 'James Coburn' },
    { id: 1811477526, name: 'Jane Fonda' },
    { id: 8689785604, name: 'Jared Leto' },
    { id: 6392738649, name: 'Jason Robards' },
    { id: 7839597351, name: 'Javier Bardem' },
    { id: 9817442942, name: 'Jeff Bridges' },
    { id: 6949006047, name: 'Jennifer Aniston' },
    { id: 2153090253, name: 'Jennifer Connelly' },
    { id: 1337198757, name: 'Jennifer Tilly' },
    { id: 3629953174, name: 'Jeremy Irons' },
    { id: 7091971426, name: 'Jessica Lange' },
    { id: 6885375349, name: 'Joaquin Phoenix' },
    { id: 5972052865, name: 'Jodie Foster' },
    { id: 2690675889, name: 'Joe Pesci' },
    { id: 2788414803, name: 'John Gielgud' },
    { id: 8884477514, name: 'John Goodman' },
    { id: 5284776100, name: 'John Hurt' },
    { id: 6528954143, name: 'John Malkovich' },
    { id: 3637504189, name: 'John Travolta' },
    { id: 4106668076, name: 'John Wayne' },
    { id: 4782151713, name: 'Johnny Depp' },
    { id: 6023459994, name: 'Joseph Fiennes' },
    { id: 7249698311, name: 'Jude Law' },
    { id: 7421532064, name: 'Judi Dench' },
    { id: 9329767532, name: 'Julia Roberts' },
    { id: 8909819154, name: 'Julianne Moore' },
    { id: 5068389870, name: 'Julie Andrews' },
    { id: 4103461020, name: 'Julie Delpy' },
    { id: 2500654397, name: 'Juliette Binoche' },
    { id: 9306639155, name: 'Juliette Lewis' },
    { id: 3051881376, name: 'Justin Theroux' },
    { id: 2694624022, name: 'Jürgen Prochnow' },
    { id: 2434031862, name: 'Kate Hudson' },
    { id: 8290874650, name: 'Kate Winslet' },
    { id: 6728264612, name: 'Kathleen Turner' },
    { id: 7631497125, name: 'Kathy Bates' },
    { id: 8488152868, name: 'Keanu Reeves' },
    { id: 9072641833, name: 'Keira Knightley' },
    { id: 6419632986, name: 'Keri Russell' },
    { id: 6606640946, name: 'Kevin Bacon' },
    { id: 1257695657, name: 'Kevin Costner' },
    { id: 9067443115, name: 'Kevin Kline' },
    { id: 5357129967, name: 'Kevin Spacey' },
    { id: 1495399443, name: 'Kim Basinger' },
    { id: 9966926887, name: 'Kirsten Dunst' },
    { id: 3254749375, name: 'Kristin Scott Thomas' },
    { id: 2823987622, name: 'Laura Dern' },
    { id: 6077430528, name: 'Laura Linney' },
    { id: 6210048137, name: 'Laurence Fishburne' },
    { id: 8896030668, name: 'Leonardo DiCaprio' },
    { id: 8505381149, name: 'Liam Neeson' },
    { id: 1603313133, name: 'Liv Tyler' },
    { id: 2553486863, name: 'Liv Ullmann' },
    { id: 8618576328, name: 'Maggie Smith' },
    { id: 2513012941, name: 'Marion Cotillard' },
    { id: 4772000763, name: 'Marlon Brando' },
    { id: 9006210188, name: 'Matt Damon' },
    { id: 9842161889, name: 'Meg Ryan' },
    { id: 5088705220, name: 'Mel Gibson' },
    { id: 9252803807, name: 'Melanie Griffith' },
    { id: 5709796360, name: 'Meryl Streep' },
    { id: 3950874925, name: 'Michael Caine' },
    { id: 1123705690, name: 'Michael Douglas' },
    { id: 2226281916, name: 'Michael Keaton' },
    { id: 3603716477, name: 'Michael Madsen' },
    { id: 9597559854, name: 'Michelle Pfeiffer' },
    { id: 8572020675, name: 'Mickey Rourke' },
    { id: 2122648066, name: 'Milla Jovovich' },
    { id: 3526953988, name: 'Morgan Freeman' },
    { id: 8709854460, name: 'Naomi Watts' },
    { id: 8862772270, name: 'Nastassja Kinski' },
    { id: 4486058658, name: 'Natalie Portman' },
    { id: 7863056659, name: 'Nick Nolte' },
    { id: 6549653231, name: 'Nicolas Cage' },
    { id: 3755826119, name: 'Nicole Kidman' },
    { id: 5564569016, name: 'Patricia Arquette' },
    { id: 2668113512, name: 'Paul Newman' },
    { id: 3733893425, name: 'Penélope Cruz' },
    { id: 6634474338, name: "Peter O'Toole" },
    { id: 1070983071, name: 'Philip Seymour Hoffman' },
    { id: 5899714492, name: "Q'orianka Kilcher" },
    { id: 2814795880, name: 'Rachel Weisz' },
    { id: 9841405032, name: 'Ralph Fiennes' },
    { id: 5011552667, name: 'Reese Witherspoon' },
    { id: 8298417879, name: 'Renée Zellweger' },
    { id: 8101089209, name: 'Richard Dreyfuss' },
    { id: 7508673166, name: 'Richard Gere' },
    { id: 4546735065, name: 'Robert De Niro' },
    { id: 1277435847, name: 'Robert Duvall' },
    { id: 7489777526, name: 'Roberto Benigni' },
    { id: 4194354338, name: 'Robin Williams' },
    { id: 5334942580, name: 'Robin Wright' },
    { id: 2591305900, name: 'Russell Crowe' },
    { id: 1977748635, name: 'Salma Hayek' },
    { id: 3079884396, name: 'Sam Shepard' },
    { id: 8332805759, name: 'Samuel L. Jackson' },
    { id: 7306599690, name: 'Sandra Bullock' },
    { id: 7671603007, name: 'Sarah Polley' },
    { id: 2129815758, name: 'Scarlett Johansson' },
    { id: 9708799963, name: 'Sean Connery' },
    { id: 8394170501, name: 'Sean Penn' },
    { id: 2649803916, name: 'Sebastian Koch' },
    { id: 5548407635, name: 'Sharon Stone' },
    { id: 7347736269, name: 'Sigourney Weaver' },
    { id: 4969491119, name: 'Steve McQueen' },
    { id: 7013789089, name: 'Susan Sarandon' },
    { id: 5903890148, name: 'Tilda Swinton' },
    { id: 1548869538, name: 'Tim Robbins' },
    { id: 2674694556, name: 'Tom Cruise' },
    { id: 1385349506, name: 'Tom Hanks' },
    { id: 4123717713, name: 'Tommy Lee Jones' },
    { id: 3540585850, name: 'Uma Thurman' },
    { id: 4826556381, name: 'Val Kilmer' },
    { id: 1372118055, name: 'Virginia Madsen' },
    { id: 5371208488, name: 'Whoopi Goldberg' },
    { id: 6003049383, name: 'Will Smith' },
    { id: 6521381385, name: 'Willem Dafoe' },
    { id: 3494505187, name: 'William Hurt' },
    { id: 6234091504, name: 'Winona Ryder' },
    { id: 6675088912, name: 'Woody Harrelson' },
    { id: 7964905155, name: 'Yves Montand' },
    { id: 8506571847, name: 'Ziyi Zhang' },
])

// const MENTION_SUGGESTIONS = [
//     { id: 8506571847, name: 'Aer' },
//     { id: 8506571847, name: 'Ana' },
//     { id: 7964905155, name: 'Anaïs' },
// ]

export { MENTION_SUGGESTIONS }
